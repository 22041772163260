import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    backgroundColor: fade(theme.palette.common.white, 0.8),
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    transition: 'box-shadow 0.3s ease',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  text: {
    color: theme.palette.grey[800],
  },
}));

const AboutUs: React.FC = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Paper className={classes.root}>
        <section className="col-sm-12 col-md-12 col-lg-12">
      <div className="intro">
        <h2>Punta Sigorta&rsquo;ya Hoş Geldiniz</h2>
        <p>
          Punta Sigorta, İstanbul&rsquo;un önde gelen acentelerinden biri olarak,
          aracılık hizmetlerinde kendini kanıtlamıştır. Müşterilere sunduğumuz
          aracılık hizmeti, güvenilir sigorta şirketlerinin en avantajlı
          tekliflerini titizlikle analiz ederek, müşterilere en uygun ve
          mükemmel seçenekleri sunmayı amaçlamaktadır. İstanbul genelinde hizmet
          veren Punta Sigorta, müşteri memnuniyetini en üst düzeye çıkarmak için
          çaba harcar. Güvenilirlik, şeffaflık ve müşteri odaklı yaklaşımımızla,
          sigorta ihtiyaçlarınızı en iyi şekilde karşılamak için buradayız.
        </p>
        <ul className="row">
          <li className="col-sm-4">
            <i className="fa fa-life-ring" />
            <h3>24x7 Destek</h3>
            <p>
              Her an yanınızdayız. Sorularınız için 7/24 destek ekibimize
              ulaşabilirsiniz.
            </p>
          </li>
          <li className="col-sm-4">
            <i className="ti-marker-alt" />
            <h3>Kolay Talep Sistemi</h3>
            <p>
              Sigorta taleplerinizi hızlı ve kolay bir şekilde başlatabilir,
              süreci yönetebilirsiniz.
            </p>
          </li>
          <li className="col-sm-4">
            <i className="ti-email" />
            <h3>Bizimle Başlayın</h3>
            <p>
              Sigorta ihtiyaçlarınız için hemen bizimle iletişime geçin. Size en
              uygun çözümleri sunalım.
            </p>
          </li>
        </ul>
      </div>
    </section>

      </Paper>
    </Container>
  );
};

export default AboutUs;