import React, { useState } from 'react';

interface FormData {
  name: string;
  email: string;
  ph_no: string;
  product: string;
  message: string;
}

const Container: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    ph_no: '',
    product: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:8000/send_mail.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Your message has been sent successfully.');
      } else {
        alert('Failed to send the message. Please try again later.');
      }
    } catch (error) {
      console.error(error);
      alert('Failed to send the message. Please try again later.');
    }
  };
  return (
    <div className="container">
  <div className="row">
    <section className="col-sm-7 col-md-8 col-lg-8">
      <div className="intro">
        <h2>Punta Sigorta&rsquo;ya Hoş Geldiniz</h2>
        <p>
          Punta Sigorta, İstanbul&rsquo;un önde gelen acentelerinden biri olarak,
          aracılık hizmetlerinde kendini kanıtlamıştır. Müşterilere sunduğumuz
          aracılık hizmeti, güvenilir sigorta şirketlerinin en avantajlı
          tekliflerini titizlikle analiz ederek, müşterilere en uygun ve
          mükemmel seçenekleri sunmayı amaçlamaktadır. İstanbul genelinde hizmet
          veren Punta Sigorta, müşteri memnuniyetini en üst düzeye çıkarmak için
          çaba harcar. Güvenilirlik, şeffaflık ve müşteri odaklı yaklaşımımızla,
          sigorta ihtiyaçlarınızı en iyi şekilde karşılamak için buradayız.
        </p>
        <ul className="row">
          <li className="col-sm-4">
            <i className="fa fa-life-ring" />
            <h3>24x7 Destek</h3>
            <p>
              Her an yanınızdayız. Sorularınız için 7/24 destek ekibimize
              ulaşabilirsiniz.
            </p>
          </li>
          <li className="col-sm-4">
            <i className="ti-marker-alt" />
            <h3>Kolay Talep Sistemi</h3>
            <p>
              Sigorta taleplerinizi hızlı ve kolay bir şekilde başlatabilir,
              süreci yönetebilirsiniz.
            </p>
          </li>
          <li className="col-sm-4">
            <i className="ti-email" />
            <h3>Bizimle Başlayın</h3>
            <p>
              Sigorta ihtiyaçlarınız için hemen bizimle iletişime geçin. Size en
              uygun çözümleri sunalım.
            </p>
          </li>
        </ul>
      </div>
    </section>
    <section className="col-sm-5 col-md-4 col-lg-4">
      <div className="get-quote-form">
        <h2>Online Hızlı Teklif Formu</h2>
        <form id="get-quote" onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              name="name"
              placeholder="Adınız"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              name="email"
              placeholder="E-posta adresiniz"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              name="ph_no"
              placeholder="Telefon No."
              value={formData.ph_no}
              onChange={handleChange}
            />
          </div>
          <div className="form-select">
            <span />
            <select name="product" value={formData.product} onChange={handleChange}>
              <option disabled value="">Ürünler (Seçiniz)</option>
              <optgroup label="Araç">
                <option>Trafik Sigortası</option>
                <option>Birleşik kasko sigortası</option>
                <option>Hesaplı kasko sigortası</option>
                <option>Elektrikli araç sigortası</option>
                <option>Marka kasko sigortaları</option>
                <option>Diğer araç sigortaları</option>
              </optgroup>
              <optgroup label="Konut">
                <option>Konut Sigortası</option>
                <option>Zorunlu deprem sigortası</option>
                <option>Bireysel çatı tipi güneş enerji panel sigortası</option>
                <option>
                  Site / Apartman yönetimi ortak alan paket sigortası
                </option>
              </optgroup>
              <optgroup label="Sağlık">
                <option>Tamamlayıcı sağlık sigortası</option>
                <option>Bireysel sağlık sigortası</option>
                <option>Riskli hastalıklar sigortası</option>
                <option>Diğer sağlık sigortaları</option>
              </optgroup>
              <optgroup label="Ferdi kaza">
                <option>Ferdi kaza sigortası</option>
                <option>Gülümseten paket ferdi kaza sigortası</option>
                <option>Güvenli gelecek sigortası</option>
              </optgroup>
              <optgroup label="Seyahat">
                <option>Yurtdışı seyahat sigortası</option>
                <option>Uçuşum güvende sigortası</option>
                <option>Yurtdışı eğitim sigortası</option>
                <option>Türkiye&rsquo;ye seyahat sigortası</option>
              </optgroup>
              <optgroup label="Size özel">
                <option>Patım güvende - Evcil hayvan sigortası</option>
                <option>Telefonum güvende sigortası</option>
                <option>Bireysel siber güvenlik sigortası</option>
              </optgroup>
              <optgroup label="Ticari ve kurumsal">
                <option>İş yeri ve KOBİ sigortaları</option>
                <option>Nakliyat sigortaları</option>
                <option>Tekne sigortaları</option>
                <option>Gezinti teknesi (yat) sigortası</option>
                <option>Sorumluluk sigortaları</option>
                <option>Havacılık sigortaları</option>
                <option>Mühendislik sigortaları</option>
                <option>Diğer sigortalar</option>
              </optgroup></select>
          </div>
          <div>
            <textarea
              placeholder="Mesaj ve teklif detaylarınız."
              value={formData.message}
              onChange={handleChange}
            />
          </div>
          <div className="text-center">
            <input
              type="submit"
              className="btn-default"
              value="Teklif avantajlarını gör"
            />
          </div>
        </form>
      </div>
    </section>
  </div>
</div>

  );
}

export default Container;
