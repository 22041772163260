import React from 'react';
import $ from 'jquery';
import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel/dist/assets/owl.theme.green.css';
import OwlCarousel from 'react-owl-carousel';

import { partnerLogo } from '../../images/imagePaths';

interface Partner {
  img: string;
  alt: string;
}

const Partners: React.FC = () => {
  const partners: Partner[] = partnerLogo.map((partner, index) => ({
    img: partner,
    alt: `Partner ${index + 1}`,
  }));

  const pairs = [];
  for (let i = 0; i < partners.length; i += 2) {
    pairs.push([partners[i], partners[i + 1]]);
  }

  const options = {
    items: 3,
    responsive: {
      0: { items: 1 },
      576: { items: 2 },
      992: { items: 3 },
    },
    merge: true,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
  };
  return (
    <section className="partners">
      <div className="container">
        <div className="row">
          <div className="parner-slider-mn">
            <div className="col-sm-3">
              <h2><b>Sigorta</b> Ortakları</h2>
            </div>
            <div className="col-sm-9">
              <OwlCarousel
                className="partner-slider owl-carousel"
                {...options}
              >
                {pairs.map((pair, index) => (
                  <div key={index} className="partner-slider-row">
                    <div className="partner-logo">
                      <p className="partner-logo-p">
                        <img className="partner-logo-img" src={pair[0].img} alt={pair[0].alt} width="100" height="50" />
                      </p>
                    </div>
                    <div className="partner-logo">
                      <p className="partner-logo-p">
                        <img className="partner-logo-img" src={pair[1] ? pair[1].img : 'images/placeholder.jpg'} alt={pair[1] ? pair[1].alt || 'Partner' : 'Partner'} width="100" height="50" />
                      </p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="clearfix"></div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;