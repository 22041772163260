// Hakkinda.tsx

import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import AboutUs from './AboutUs';
// Import other scripts as needed

const Hakkinda: React.FC = () => {
  const breadcrumbs = [
    { name: 'Anasayfa', path: '/' },
    { name: 'Hakkinda', path: '/Hakkinda' },
  ];
  return (
    <div id="page-content">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <AboutUs />
   </div>
  );
}

export default Hakkinda;