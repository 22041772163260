// Iletisim.tsx

import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import ContactUs from './contactUs';

const Iletisim: React.FC = () => {
  const breadcrumbs = [
    { name: 'Anasayfa', path: '/' },
    { name: 'İletişim', path: '/Iletisim' },
  ];
  return (
    <div id="page-content">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ContactUs/>
   </div>
  );
}

export default Iletisim;