// imagePaths.js
import sliderImg1 from './puntasigorta-Seyahat-Sigortası-slider-img.jpg';
import sliderImg2 from './puntasigorta-saglik-sigortasi-slider-img.jpg';
import sliderImg3 from './puntasigorta-trafik-sigortasi-slider-img.jpg';

import productImg1 from './product-img.jpg'
import productImg2 from './1.jpg'
import productImg3 from './3.jpg'
import productImg4 from './puntasigorta-trafik-sigortasi-slider-img2.jpg'

import partnerLogo1 from './partner-logo-01.jpg'
import partnerLogo2 from './partner-logo-02.jpg'
import partnerLogo3 from './partner-logo-03.jpg'
import partnerLogo4 from './partner-logo-04.jpg'
import partnerLogo5 from './partner-logo-05.jpg'
import partnerLogo6 from './partner-logo-06.jpg'
import partnerLogo7 from './partner-logo-07.jpg'
import partnerLogo8 from './partner-logo-08.jpg'
import partnerLogo9 from './partner-logo-09.jpg'
import partnerLogo10 from './partner-logo-10.jpg'
import partnerLogo11 from './partner-logo-11.jpg'
import partnerLogo12 from './partner-logo-12.jpg'
import partnerLogo13 from './partner-logo-13.jpg'
import partnerLogo14 from './partner-logo-14.jpg'
import partnerLogo15 from './partner-logo-15.jpg'
import partnerLogo16 from './partner-logo-16.jpg'
import partnerLogo17 from './partner-logo-17.jpg'
import partnerLogo18 from './partner-logo-18.jpg'
import partnerLogo19 from './partner-logo-19.jpg'

export const sliderImages = [sliderImg1, sliderImg2, sliderImg3];
export const productImg = [productImg1, productImg2, productImg3, productImg4];
export const partnerLogo = [partnerLogo1, partnerLogo2, partnerLogo3, partnerLogo4, partnerLogo5, partnerLogo6, partnerLogo7, partnerLogo8, partnerLogo9, partnerLogo10, partnerLogo11, partnerLogo12, partnerLogo12, partnerLogo13, partnerLogo14, partnerLogo15, partnerLogo16, partnerLogo17, partnerLogo18, partnerLogo19];
