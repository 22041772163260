import React, { useState } from "react";
import { productImg } from "../../images/imagePaths";

interface TabContent {
  title: string;
  icon: string;
  text: string;
  text2: string;
  img: string;
}

interface ProductTabProps {
  className?: string;
}

const ProductTab: React.FC<ProductTabProps> = ({ className }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabContents: TabContent[] = [
    {
      title: "Konut Sigortası",
      icon: "ti-home",
      text: "Konut Sigortası, ev sahiplerini beklenmedik durumlar karşısında güvence altına alır...",
      text2: "Konut sigortası, ev sahiplerine yangın, hırsızlık, doğal afetler ve diğer çeşitli risklere karşı kapsamlı bir koruma sunar. Güvenilir bir konut sigortası ile eviniz her zaman güvende olacaktır.",
      img: productImg[0],
    },
    {
      title: "Seyahat Sigortası",
      icon: "fa fa-plane",
      text: "Seyahat sigortası, seyahat edenleri beklenmedik durumlar karşısında koruma altına alır...",
      text2: "Seyahat sigortası, seyahat edenlere seyahat sırasında karşılaşabilecekleri beklenmedik durumlara karşı maddi koruma sağlar. Sağlık sorunları, uçuş iptalleri, kayıp bagaj ve diğer riskler için güvence sağlar.",
      img: productImg[1],
    },
    
    {
      title: "Sağlık Sigortası",
      icon: "ti-heart-broken",
      text: "Sağlık sigortası, sizin ve ailenizin sağlık masraflarını karşılamak için önemli bir güvencedir...",
      text2: "Sağlık sigortası, beklenmedik sağlık sorunlarına karşı maddi koruma sağlar ve tıbbi masrafları karşılar. Acil durumlarda sakin ve güvende olmanızı sağlar.",
      img: productImg[2],
    },
    {
      title: "Trafik Sigortası",
      icon: "ti-car",
      text: "Trafik sigortası, yolda karşılaşılabilecek beklenmedik durumlara karşı sizi ve aracınızı korur...",
      text2: "Trafik sigortası, trafik kazaları ve araç hasarları gibi beklenmedik durumlarda maddi koruma sağlar. Güvenli ve sorunsuz bir sürüş deneyimi için önemlidir.",
      img: productImg[3],
    },
  ];

  return (
    <section className="product-tab">
  <div className="container">
    <div className="row">
      <div id="parentVerticalTab">
        <h2>Ürünler</h2>
        <ul className="resp-tabs-list hor_1 col-sm-3 col-md-3 col-lg-3">
          {tabContents.map((content, index) => (
            <li key={content.title} onClick={() => setSelectedTab(index)}>
              <i className={content.icon}></i> {content.title}
            </li>
          ))}
        </ul>
        <div className="col-sm-5 col-md-5 col-lg-5 resp-tabs-container hor_1">
          <div>
            <div className="prod-tab-content">
              <h4>
                <span className="prod-cion">
                  <i className={tabContents[selectedTab].icon} />
                </span>
                {tabContents[selectedTab].title}
              </h4>
              <p>{tabContents[selectedTab].text}</p>
              <p>{tabContents[selectedTab].text2}</p>
              <p className="tel">
                <i className="fa fa-phone" /> +90 212 515 72 00 <span>Bizi Şimdi Arayın</span>
              </p>
              <p>
                <a className="btn-default" href="#">
                Online Poliçe / حول الاقتباس
                </a>
              </p>
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  </div>
</section>

  );
};

export default ProductTab;
