import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="container">
        <div className="row contact-sec">
          <div className="col-md-5 col-lg-5">
            <h2>Punta <span>Sigorta</span></h2>
            <div className="row">
              <div className="col-sm-6">
                <p>
                  Güneşli, No:24 1 Yılmazlar İş Hanı, Üsküp Cd, 34212 Bağcılar/İstanbul, Türkiye
                  <br />Zip - 34212
                </p>
              </div>
              <div className="col-sm-6">
                <ul>
                  <li>
                    <a href="#"><i className="fa fa-phone"></i> +90 212 515 72 00</a>
                  </li>
                  <li>
                    <a href="#"><i className="ti-email"></i> adilarvas@puntasigorta.com.tr</a>
                  </li>
                </ul>
              </div>
            </div>
            <a href="#" className="btn-default">Bize Ulaşın</a>
          </div>
          <div className="col-md-5 col-lg-5 col-md-offset-2 col-lg-offset-2">
            <h2>Temsilci<span>Detayı</span></h2>
            <div className="row">
              <div className="col-sm-6">
                <p>
                  Güneşli, No:24 1 Yılmazlar İş Hanı, Üsküp Cd, 34212 Bağcılar/İstanbul, Türkiye
                  <br />Zip - 34212
                </p>
              </div>
              <div className="col-sm-6">
                <ul>
                  <li>
                    <a href="#"><i className="fa fa-phone"></i> +90 212 515 72 00</a>
                  </li>
                  <li>
                    <a href="#"><i className="ti-email"></i> destek@puntasigorta.com.tr</a>
                  </li>
                </ul>
              </div>
            </div>
            <a href="#" className="btn-default">İletişim Temsilcisi</a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 col-lg-8">
            <ul className="footer-nav">
              <li><a href="/">Anasayfa</a></li>
              <li><a href="/Hakkinda">Hakkinda</a></li>
              <li><a href="/Urunler">Ürünler</a></li>
              <li><a href="/Iletisim">Contact us</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
            Copyright &copy; 2018 Puntasigorta tarafından dağıtılmaktadır
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 text-right">
              <a href="/Sartlar_ve_Kosullar">Şartlar ve Koşullar</a>
              <a href="/Politika">Politika</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
