import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel/dist/assets/owl.theme.green.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CSSTransition } from 'react-transition-group';

// Ürün veri türünü tanımla
interface Product {
  name: string;
  description: string;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

  
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

// Ürün kategorileri ve ilgili ürünler için yer tutucu veri
const productData: { [key: string]: Product[] } = {
  Araç: [
    {
      name: "Trafik Sigortası",
      description:
        "Trafik sigortası, araçların trafik kazalarından kaynaklanan fiziksel hasar veya yaralanmaya karşı finansal kayıpları güvence altına alır.",
    },
    {
      name: "Birleşik kasko sigortası",
      description:
        "Birleşik kasko sigortası, aracın çeşitli nedenlerle zarar görmesi durumunda oluşabilecek maddi kayıpları kapsayan kapsamlı bir sigorta çözümüdür.",
    },
    {
      name: "Hesaplı kasko sigortası",
      description:
        "Hesaplı kasko sigortası, uygun fiyatlarla aracınızı çeşitli risklere karşı korur ve finansal güvence sağlar.",
    },
    {
      name: "Elektrikli araç sigortası",
      description:
        "Elektrikli araç sigortası, elektrikli araçların özel ihtiyaçlarına ve risklerine uygun olarak tasarlanmış bir sigorta çözümüdür.",
    },
    {
      name: "Marka kasko sigortaları",
      description:
        "Marka kasko sigortaları, belirli marka ve modellere özel olarak tasarlanmış kapsamlı bir sigorta çözümü sunar.",
    },
    {
      name: "Diğer araç sigortaları",
      description:
        "Diğer araç sigortaları, özel ihtiyaçlara veya koşullara yönelik olarak farklı kapsamlar sunan çeşitli sigorta türlerini içerir.",
    },
  ],

  Konut: [
    {
      name: "Konut Sigortası",
      description:
        "Konut sigortası, konutunuzu çeşitli risklere karşı korur ve olası maddi kayıpları güvence altına alır.",
    },
    {
      name: "Zorunlu deprem sigortası",
      description:
        "Zorunlu deprem sigortası, Türkiye'deki konut sahiplerinin deprem riskine karşı korunmasını sağlayan zorunlu bir sigorta türüdür.",
    },
    {
      name: "Bireysel çatı tipi güneş enerji panel sigortası",
      description:
        "Bireysel çatı tipi güneş enerji panel sigortası, konutlarda bulunan güneş enerji panellerini çeşitli risklere karşı korur ve maddi kayıpları güvence altına alır.",
    },
    {
      name: "Site / Apartman yönetimi ortak alan paket sigortası",
      description:
        "Site veya apartman yönetimi ortak alan paket sigortası, site veya apartmanın ortak kullanım alanlarını çeşitli risklere karşı korur ve olası maddi kayıpları güvence altına alır.",
    },
  ],

  Sağlık: [
    {
      name: "Tamamlayıcı sağlık sigortası",
      description:
        "Tamamlayıcı sağlık sigortası, temel sağlık sigortası poliçesinin yanında ek bir koruma sağlayarak sağlık harcamalarınızı destekler.",
    },
    {
      name: "Bireysel sağlık sigortası",
      description:
        "Bireysel sağlık sigortası, kişinin kendi sağlık harcamalarını karşılamak için satın aldığı bir sigorta türüdür.",
    },
    {
      name: "Riskli hastalıklar sigortası",
      description:
        "Riskli hastalıklar sigortası, belirli riskli hastalıklara karşı finansal koruma sağlayan bir sigorta türüdür.",
    },
    {
      name: "Diğer sağlık sigortaları",
      description:
        "Diğer sağlık sigortaları, özel ihtiyaçlara veya koşullara yönelik olarak farklı kapsamlar sunan çeşitli sağlık sigortası çözümlerini içerir.",
    },
  ],

  Ferdi_kaza: [
    {
      name: "Ferdi kaza sigortası",
      description:
        "Ferdi kaza sigortası, bireyin kaza sonucu oluşabilecek yaralanma veya ölüm durumlarında maddi koruma sağlayan bir sigorta türüdür.",
    },
    {
      name: "Gülümseten paket ferdi kaza sigortası",
      description:
        "Gülümseten paket ferdi kaza sigortası, ferdi kaza sigortasının yanı sıra ek avantajlar sunarak kişiyi daha kapsamlı bir şekilde korur.",
    },
    {
      name: "Güvenli gelecek sigortası",
      description:
        "Güvenli gelecek sigortası, gelecekteki belirsizliklere karşı maddi güvence sağlayan bir sigorta türüdür, ferdi kazalara yönelik ek koruma içerebilir.",
    },
  ],
  Seyehat: [
    {
      name: "Yurtdışı seyahat sigortası",
      description:
        "Yurtdışı seyahat sigortası, seyahat sırasında meydana gelebilecek beklenmedik durumlar için maddi ve tıbbi koruma sağlayan bir sigorta türüdür.",
    },
    {
      name: "Uçuşum güvende sigortası",
      description:
        "Uçuşum güvende sigortası, uçuşunuzda meydana gelebilecek olumsuz durumlar için maddi ve tıbbi yardım sağlayan bir sigorta poliçesidir.",
    },
    {
      name: "Yurtdışı eğitim sigortası",
      description:
        "Yurtdışı eğitim sigortası, yurtdışında eğitim alacak kişiler için sağlık, güvenlik ve diğer risklere karşı koruma sağlayan bir sigorta türüdür.",
    },
    {
      name: "Türkiye'ye seyahat sigortası",
      description:
        "Türkiye'ye seyahat sigortası, Türkiye'ye yapılan seyahatlerde meydana gelebilecek beklenmedik durumlar için maddi ve tıbbi koruma sağlayan bir sigorta poliçesidir.",
    },
  ],
  Size_Özel: [
    {
      name: "Patım güvende - Evcil hayvan sigortası",
      description:
        "Patım güvende sigortası, evcil hayvan sahiplerinin evcil hayvanlarının sağlık ve güvenliği için maddi koruma sağlayan bir sigorta türüdür.",
    },
    {
      name: "Telefonum güvende sigortası",
      description:
        "Telefonum güvende sigortası, cep telefonu sahiplerinin telefonlarının çalınması, kaybolması veya hasar görmesi durumunda maddi koruma sağlayan bir sigorta poliçesidir.",
    },
    {
      name: "Bireysel siber güvenlik sigortası",
      description:
        "Bireysel siber güvenlik sigortası, kişisel bilgi ve dijital varlıkların çevrimiçi tehditlere karşı korunması için maddi koruma sağlayan bir sigorta türüdür.",
    },
  ],
  Ticari_ve_Kurumsal: [
    {
      name: "İş yeri ve KOBİ sigortaları",
      description:
        "İş yeri ve KOBİ sigortaları, küçük ve orta ölçekli işletmelerin iş yerleri ve faaliyetleri için çeşitli risklere karşı koruma sağlayan sigorta ürünlerini içerir.",
    },
    {
      name: "Nakliyat sigortaları",
      description:
        "Nakliyat sigortaları, taşımacılık sektöründe faaliyet gösteren firmaların kargo ve taşımacılık işlemleri sırasında oluşabilecek risklere karşı koruma sağlayan sigorta türleridir.",
    },
    {
      name: "Tekne sigortaları",
      description:
        "Tekne sigortaları, denizde veya iç sularda seyreden tekne ve yatların çeşitli risklere karşı korunması için tasarlanmış sigorta ürünleridir.",
    },
    {
      name: "Gezinti teknesi (yat) sigortası",
      description:
        "Gezinti teknesi (yat) sigortası, özel amaçlar için kullanılan ve deniz gezileri için tasarlanmış tekne veya yatların çeşitli risklere karşı korunması için sunulan bir sigorta türüdür.",
    },
    {
      name: "Sorumluluk sigortaları",
      description:
        "Sorumluluk sigortaları, işletmelerin veya bireylerin üçüncü şahıslara veya mülkiyete verilebilecek zararlara karşı finansal koruma sağlayan sigorta ürünleridir.",
    },
    {
      name: "Havacılık sigortaları",
      description:
        "Havacılık sigortaları, havayolu şirketlerinin uçakları, hava taşımacılığı hizmetleri ve diğer havacılık faaliyetleri için çeşitli risklere karşı koruma sağlayan sigorta türlerini içerir.",
    },
    {
      name: "Mühendislik sigortaları",
      description:
        "Mühendislik sigortaları, inşaat projeleri, mühendislik işleri ve diğer teknik faaliyetler sırasında oluşabilecek risklere karşı koruma sağlayan sigorta ürünlerini içerir.",
    },
    {
      name: "Diğer sigortalar",
      description:
        "Diğer sigortalar kategorisi, ticari ve kurumsal alanlarda özel ihtiyaçlara veya koşullara yönelik olarak farklı kapsamlar sunan çeşitli sigorta türlerini içerir.",
    },
  ],
};

const Products: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
  };
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const options = {
    items: 3,
    responsive: {
      0: { items: 1 },
      576: { items: 2 },
      992: { items: 3 },
    },
    merge: true,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  


  return (
    <section className="partners">
      <div className="container">
        <div className="row">
          <div className="parner-slider-mn">
            <div className="col-sm-3">
              <h2>
                <b>Sigorta</b> Ürünleri
              </h2>

            </div>
            <div className="col-sm-9">
              <OwlCarousel className="partner-slider owl-carousel" {...options}>
                {Object.keys(productData).map((category, index) => (
                  <div
                    key={index}
                    className="partner-slider-item"
                    onClick={() => handleCategoryClick(category)}
                  >
                    <h3 className="product-list-item">{category}</h3>
                    <ul>
                      {productData[category].slice(0, 2).map((product, idx) => (
                        <li className="product-list-item" key={idx}>{product.name}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: 224,
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {selectedCategory &&
            productData[selectedCategory].map((product, index) => (
              <Tab key={index} label={product.name} {...a11yProps(index)} />
            ))}
        </Tabs>
        {selectedCategory &&
  productData[selectedCategory].map((product, index) => (
    <TabPanel key={index} value={value} index={index}>
      <CSSTransition
        in={true}
        appear={true}
        timeout={500}
        classNames="slide-right" // Yönlendirme animasyonu için sınıf adı
      >
        <div className="col-sm-8 col-md-8 col-lg-8">
          <h2>{product.name}</h2>
          <p style={{ fontSize: 16 }}>{product.description}</p>
        </div>
      </CSSTransition>
    </TabPanel>
  ))}
  
      </Box>
    </section>
  );
};

export default Products;
