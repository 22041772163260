// Urunler.tsx

import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import Products from './Products';

const Urunler: React.FC = () => {
  const breadcrumbs = [
    { name: 'Anasayfa', path: '/' },
    { name: 'Ürünler', path: '/Urunler' },
  ];
  return (
    <div id="page-content">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Products />
   </div>
  );
}

export default Urunler;