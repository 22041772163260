import React from 'react';

const Services: React.FC = () => {
  return (
    <section className="services">
  <h2 className="text-center">Hizmetlerimiz</h2>
  <div className="container">
    <div className="row">
      <div className="col-sm-3 services-dtl">
        <span className="fa fa-life-bouy" />
        <h3>24/7 Destek</h3>
        <p>Hızlı ve güvenilir destek her zaman.</p>
      </div>
      <div className="col-sm-3 services-dtl">
        <span className="ti-pencil" />
        <h3>Kolay Talep Sistemi</h3>
        <p>Hızlı ve kolay talep işlemleri.</p>
      </div>
      <div className="col-sm-3 services-dtl">
        <span className="ti-email" />
        <h3>Bizimle Başlayın</h3>
        <p>Güvence altına alın, avantajlı teklifler.</p>
      </div>
      <div className="col-sm-3 services-dtl">
        <span className="fa fa-money" />
        <h3>Kolay Taksitler</h3>
        <p>Kolay ve esnek taksitlerle prim ödemeleri.</p>
      </div>
      <div className="col-sm-3 services-dtl">
        <span className="ti-wallet" />
        <h3>Özel Sigorta Çözümleri</h3>
        <p>İhtiyacınıza uygun özel sigorta çözümleri.</p>
      </div>
      <div className="col-sm-3 services-dtl">
        <span className="fa fa-laptop" />
        <h3>Online Hesap</h3>
        <p>Çevrimiçi hesap yönetimi ile bilgi ve ödemeleri takip edin.</p>
      </div>
      <div className="col-sm-3 services-dtl">
        <span className="ti-lock" />
        <h3>Güçlü Güvenlik</h3>
        <p>Güçlü güvenlik önlemleri ile müşteri bilgileri koruma altında.</p>
      </div>
      <div className="col-sm-3 services-dtl">
        <span className="ti-book" />
        <h3>Anlaşılabilir Şartlar</h3>
        <p>Şeffaf ve anlaşılır sigorta şartları sunuyoruz.</p>
      </div>
    </div>
  </div>
</section>

  );
};

export default Services;
