// App.tsx
import React from 'react';
import Home from './pages/home'; // Updated import path
import Hakkinda from './pages/Hakkinda/Hakkinda';
import Iletisim from './pages/Iletisim/Iletisim';
import Header from './components/Header';
import Footer from './components/Footer';
import Urunler from './pages/Urunler/Urunler';




function App() {
   
  
  let pages
  switch (window.location.pathname) {
    case "/":
      pages = <Home />
      break
    case "/Hakkinda":
      pages = <Hakkinda />
      break
      case "/Iletisim":
        pages = <Iletisim />
        break
        case "/Urunler":
        pages = <Urunler />
        break
        
  }
  return (
    <>
    <Header />
      <div className="clear"></div>
      {pages}
      <div className="clear"></div>
      <Footer />
      
    </>
  );
}

export default App;
