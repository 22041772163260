// Iletisim.tsx

import React from 'react';

const ContactUs: React.FC = () => {
  return (
    <section className="contact-us">
  <div className="container">
    <div className="row">
      <div className="col-md-8 col-md-offset-2">
        <div className="form-box">
          <h2>İletişime Geçin</h2>
          <div className="form-content">
            <input type="text" name="name" placeholder="Sizin adınız" />
            <input type="text" name="email" placeholder="Email" />
            <input type="text" name="subject" placeholder="Konu" />
            <textarea
              rows={1}
              cols={1}
              name="message"
              placeholder="Message"
              defaultValue={""}
            />
            <div className="text-center">
              <input
                type="submit"
                className="btn-default"
                defaultValue="Gönder"
                value="Gönder"
              />
            </div>
          </div>
        </div>
        <div className="contact-info">
          <div className="col-sm-6">
            <div className="row">
              <div className="contact-address">
                <h3>Address</h3>
                <div>
                  <i className="icon ti-home" />
                  <p>
                  Güneşli, No:24 1 Yılmazlar İş Hanı,
                    <br />
                    Üsküp Cd, 34212 Bağcılar
                    <br />
                    İstanbul, Türkiye
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="row">
              <div className="contact-dtl">
                <h3>Temsilci Detayı</h3>
                <div>
                  <i className="icon fa fa-phone" />
                  <p>+90 212 515 72 00</p>
                </div>
                <div>
                  <i className="icon ti-email" />
                  <p>
                    <a href="#">destek@puntasigorta.com.tr</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12038.485242506073!2d28.8267469!3d41.0335402!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa4e2e32e04d5%3A0x235a52c844d9f8b1!2zUHVudGEgU2lnb3J0YSBBcmFjxLFsxLFrIEhpem1ldGxlcmkgTFREIMWeVMSw!5e0!3m2!1sen!2suk!4v1707248571650!5m2!1sen!2suk"
            width="100%"
            height={260}
            frameBorder={0}
            style={{ border: 0 }}
          />
        </div>
      </div>
    </div>
  </div>
</section>

  );
}

export default ContactUs;