import React from 'react';

interface Breadcrumb {
  name: string;
  path?: string;
}

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  return (
    <section className="breadcrumb">
      <div className="container">
        <h2>{breadcrumbs[breadcrumbs.length - 1].name}</h2>
        <ul>
          {breadcrumbs.map((breadcrumb, index) => (
            <li key={index}>
              {index !== 0 && <span>&gt;</span>}
              {breadcrumb.path ? (
                <a href={breadcrumb.path}>{breadcrumb.name}</a>
              ) : (
                <span>{breadcrumb.name}</span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Breadcrumbs;