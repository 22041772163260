import React, { useEffect, useState } from "react";
import { Button } from '@material-ui/core'; // Add this line
import AdvertisementModal from "../pages/Home/AdvertisementModal";
const Header: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    // jQuery to collapse the navbar on scroll
    function collapseNavbar() {
      if (window.scrollY > 50) {
        const navbar = document.querySelector(".navbar-fixed-top") as HTMLElement;
        if (navbar) {
          navbar.classList.add("top-nav-collapse");
        }
      } else {
        const navbar = document.querySelector(".navbar-fixed-top") as HTMLElement;
        if (navbar) {
          navbar.classList.remove("top-nav-collapse");
        }
      }
    }

    window.addEventListener("scroll", collapseNavbar);

    // Optionally, you might want to trigger the function on component mount
    collapseNavbar();

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", collapseNavbar);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  useEffect(() => {
    // Closes the Responsive Menu on Menu Item Click
    const menuItems = document.querySelectorAll('.navbar-collapse ul li a') as NodeListOf<HTMLElement>;

    const handleClick = (event: MouseEvent) => {
      const target = event.currentTarget as HTMLElement;
      const isDropdownToggle = target.classList.contains('dropdown-toggle');
      const isActive = target.classList.contains('active');

      if (!isDropdownToggle && !isActive) {
        const navbarToggle = document.querySelector('.navbar-toggle:visible') as HTMLElement;
        if (navbarToggle) {
          navbarToggle.click();
        }
      }
    };

    menuItems.forEach((menuItem) => {
      menuItem.addEventListener('click', handleClick);
    });

    // Cleanup the event listeners when the component unmounts
    return () => {
      menuItems.forEach((menuItem) => {
        menuItem.removeEventListener('click', handleClick);
      });
    };
  }, []); // Empty dependency array to run the effect only once on mount
// <AdvertisementModal open={modalOpen} onClose={handleCloseModal} />
  return (
    <header>
      <div className="top-bar">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 address">
              <i className="ti-location-pin" /> Güneşli, No:24 1 Yılmazlar İş
              Hanı, Üsküp Cd, 34212 Bağcılar/İstanbul, Türkiye
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-custom navbar-fixed-top" role="navigation">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-main-collapse"
            >
              <i className="fa fa-bars" />
            </button>
            <a className="navbar-brand" href="/">
              Punta<span>Sigorta</span>
            </a>
            <p>
              Bizi Şimdi Arayın <b>+90 212 515 72 00</b>
            </p>
          </div>
          <div className="collapse navbar-collapse navbar-main-collapse">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="/">Anasayfa</a>
              </li>
              <li>
                <a href="/Hakkinda">Hakkında</a>
              </li>

              <li className="dropdown">
                <a
                  href="/Urunler"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Ürünler <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href="/Urunler/Arac">Araç</a>
                  </li>
                  <li>
                    <a href="/Urunler/Saglik">Sağlık</a>
                  </li>
                  <li>
                    <a href="/Urunler/Konut">Konut</a>
                  </li>
                  <li role="separator" className="divider" />
                  <li className="dropdown-header">Daha fazlası için</li>
                  <li>
                    <a href="/Urunler">Tüm Ürünler</a>
                  </li>
                  <li>
                    <a  onClick={handleOpenModal}>Size Özel Fırsatlar</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/Iletisim">İletişim</a>
              </li>
              <li>
                {/* Button trigger modal */}
                <button
                  type="button"
                  className="btn btn-default"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  Online Poliçe / حول الاقتباس
                </button>
                {/* Modal */}
                <div
                  className="modal fade"
                  id="staticBackdrop"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title fs-5"
                          id="staticBackdropLabel"
                        >
                          Modal title
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">...</div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button type="button" className="btn btn-primary">
                          Understood
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
