// Home.tsx

import React, { useState } from 'react';
import Flexslider from './Home/Flexslider';
import Container from './Home/Container';
import ProductTab from './Home/ProductTab';
import Services from './Home/Services';
import Partners from './Home/Partners';
import { Button } from '@material-ui/core'; // Add this line
import AdvertisementModal from './Home/AdvertisementModal';


// Import other scripts as needed

const Home: React.FC = () => {
{/*
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
*/}
  return (
      <div id="page-content">
        <Flexslider />
        <Container />
        <ProductTab />
        <Services />
        <Partners />
       {/* <Button variant="contained" color="primary" onClick={handleOpenModal}> 
        Open Advertisement
      </Button>
        <AdvertisementModal open={modalOpen} onClose={handleCloseModal} />*/}
      </div>
  );
}

export default Home;
