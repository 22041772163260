import React, { useEffect } from 'react';
import { sliderImages } from '../../images/imagePaths';

const sliderData = [
  {
    title: "Sağlık hizmeti",
    subtitle: "Sağlık Sigortası",
    description: "Sağlık sigortamız, sizin ve sevdiklerinizin sağlığını en üst düzeyde koruma altına alır. Uygun fiyatlarla geniş kapsamlı sağlık ...",
    link: "#",
  },
  {
    title: "Trafik Hizmeti",
    subtitle: "Trafik Sigortası",
    description: "Trafik sigortamız, sizi yolda beklenmedik durumlar karşısında korur. Her an her yerde güvende olmanız için tasarlanmış ...",
    link: "#",
  },
  {
    title: "Güvenli Seyahat",
    subtitle: "Seyahat Sigortası",
    description: "Seyahat sigortası, yolculuklarınız sırasında güvenliğinizi ve huzurunuzu sağlar. Yeni yerler keşfederken ya da plajda ...",
    link: "#",
  },
];

const Flexslider: React.FC = () => {
  useEffect(() => {
    // Load FlexSlider script dynamically
    const script = document.createElement('script');
    script.src = 'https://technext.github.io/insurance-press/js/jquery.flexslider-min.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Initialize FlexSlider once the script is loaded
      $('.flexslider').flexslider({
        animation: 'fade',
        start: function (slider) {
          $('body').removeClass('loading');
        },
      });
    };

    // Cleanup: Remove the script from the document when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <section className="flexslider">
      <ul className="slides">
        {sliderData.map((slide, index) => (
          <li key={index}>
            <img src={sliderImages[index]} alt={`Slider ${index + 1}`} />
            <div className="slide-info">
              <div className="slide-con">
                <b>{slide.title}</b>
                <h3>{slide.subtitle}</h3>
                <p>{slide.description}</p>
                <a href={slide.link} className="ti-arrow-right" />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Flexslider;